<template>
  <div class="d-flex justify-content-between border-bottom">
    <select-input
      name="Printer"
      label="Printer"
      class="col"
      valueExpr="printerId"
      :items="labPrinters"
      v-model="bin.printerId"
    />
    <span class="align-self-center mt-2 mx-2 text-large"> &rarr;</span>
    <select-input
      name="Printer"
      label="Redirect Printer"
      :items="allPrinters"
      class="col"
      valueExpr="printerId"
      v-model="bin.redirectPrinterId"
      :disabled="hasBinSelected"
      v-if="!isFileDrop"
    />
    <select-input
      class="col"
      :disabled="hasRedirectPrinter"
      name="Printer"
      label="Printer Bin"
      valueExpr="value"
      :items="printerBins"
      v-model="bin.bin"
      v-if="!isFileDrop"
    />
    <select-input
      class="col"
      name="TrayName"
      label="Tray Name"
      v-model="bin.bin"
      v-if="isFileDrop"
      :items="fileDropTrayOptions"
      displayExpr="displayName"
      valueExpr="displayName"
    />
    <div class="col" v-if="isFileDrop" />
    <icon-button
      icon="trash-alt"
      class="btn-danger delete-btn align-self-center ml-1 mt-3"
      @click="$emit('delete')"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import IconButton from "./common/IconButton.vue";
import SelectInput from "./common/SelectInput.vue";
import { helpers, required } from "vuelidate/lib/validators";
export default {
  components: { SelectInput, IconButton },
  name: "PrinterBinMap",
  props: {
    allPrinters: Array,
    labPrinters: Array,
    value: Object
  },
  data() {
    return {
      printerBins: [],
      isFileDrop: false
    };
  },
  mounted() {
    if (this.FileDropPrinting?.printerName && !this.labPrinters.find(e => e?.fileDrop)) {
      this.labPrinters.push({
        displayName: "File Drop (" + this.FileDropPrinting.printerName + ")",
        printerId: parseInt(process.env.VUE_APP_FILE_DROP_PRINTER_ID),
        fileDrop: true
      });
    }
  },
  computed: {
    ...mapState({
      LabPrintNodeAPIKey: state => state.labSettings.LabPrintNodeAPIKey,
      FileDropPrinting: state => JSON.parse(state.labSettings.FileDropPrintingConfiguration)
    }),
    bin: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    hasRedirectPrinter() {
      return !!this.bin.redirectPrinterId;
    },
    hasBinSelected() {
      return !!this.bin.bin;
    },
    fileDropTrayOptions() {
      return this.FileDropPrinting?.trayOptions || [];
    }
  },
  validations() {
    return {
      bin: {
        printerId: {
          required
        },
        bin: {
          required: value => {
            return this.isFileDrop ? helpers.req(value) : true;
          }
        }
      }
    };
  },
  watch: {
    "bin.printerId": {
      immediate: true,
      handler(nv) {
        if (nv === parseInt(process.env.VUE_APP_FILE_DROP_PRINTER_ID)) {
          this.isFileDrop = true;
          return;
        }
        this.isFileDrop = false;
        if (nv !== null) {
          this.getPrinterCapabilities(nv);
        }
      }
    }
  },
  methods: {
    async getPrinterCapabilities(printerId) {
      const targetPrinter = this.allPrinters.find(e => e.printerId === printerId);
      if (!targetPrinter?.printNodeId) {
        return;
      }
      const encodedApiKey = Buffer.from(this.LabPrintNodeAPIKey).toString("base64");
      const printnodeData = await fetch(
        `https://api.printnode.com/printers/${targetPrinter.printNodeId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Basic ${encodedApiKey}`,
            "Content-Type": "application/json"
          }
        }
      );
      const data = await printnodeData.json();
      if (Array.isArray(data) && Array.isArray(data[0]?.capabilities?.bins)) {
        this.printerBins = data[0].capabilities.bins.map(e => ({ displayName: e, value: e }));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.text-large {
  font-size: 1.5rem;
}
</style>
