var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container',{},[_c('div',{staticClass:"p-2 d-flex align-items-center justify-content-between"},[_c('page-title',[_vm._v("Printer Maintenance")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.toggleBinMaps}},[_vm._v("Bin Maps")])],1),_c('div',{staticClass:"d-flex flex-md-wrap"},[_c('div',{staticClass:"col"},_vm._l((_vm.computers),function(computer){return _c('Card',{key:computer.id,staticClass:"p-3",class:{ active: computer.id === _vm.currentComputerId },scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"font-weight-bold text-large"},[_vm._v("🖥️"+_vm._s(computer.name))]),_c('icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(computer.state),expression:"computer.state"}],staticClass:"ml-auto ml-2",class:{
              'text-success': computer.state === 'connected',
              'text-danger': computer.state === 'disconnected'
            },attrs:{"icon":"circle"}}),_c('icon-button',{staticClass:"mx-1",attrs:{"icon":"eye"},on:{"click":function($event){return _vm.handleComputerExpand(computer.id)}}})]},proxy:true}],null,true)})}),1),_c('div',{staticClass:"col-8"},[(_vm.currentComputerId !== null)?[_c('div',{staticClass:"p-2"},_vm._l((_vm.printers),function(printer){return _c('Card',{key:printer.id,staticClass:"p-1",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"mx-2 font-weight-bold text-large"},[_vm._v("🖨️"+_vm._s(printer.name))]),_c('icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(printer.state),expression:"printer.state"}],staticClass:"ml-auto mx-2",class:{
                  'text-success': printer.state === 'online',
                  'text-danger': printer.state === 'offline'
                },attrs:{"icon":"circle"}}),(printer.id == _vm.currentPrinterId)?_c('DxSwitch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Hide'),expression:"'Hide'"}],attrs:{"onValueChanged":_vm.handleSetIgnoredStatus,"items":_vm.booleanOptions,"switchedOnText":"Hidden","switchedOffText":"Visible"},model:{value:(printer.isIgnored),callback:function ($$v) {_vm.$set(printer, "isIgnored", $$v)},expression:"printer.isIgnored"}}):_vm._e(),_c('icon-button',{attrs:{"icon":_vm.currentPrinterId === printer.id ? 'chevron-up' : 'chevron-down'},on:{"click":function($event){return _vm.handlePrinterExpand(printer.id)}}})]},proxy:true},(_vm.currentPrinterId === printer.id)?{key:"body",fn:function(){return [_c('div',[_c('dx-grid-with-search',{attrs:{"columns":_vm.printJobColumns,"dataSource":_vm.printJobs,"selection":_vm.selection},scopedSlots:_vm._u([{key:"stateIcon",fn:function(ref){
                var data = ref.data;
return [_c('div',{staticClass:"d-flex text-center flex-column"},[_c('Icon',_vm._b({staticClass:"mx-auto"},'Icon',_vm.printJobIcon(data),false),[_vm._v(" "+_vm._s(_vm.printJobIcon(data).icon ? "" : data.state)+" ")]),_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(data.state === "deleted" ? "Canceled" : data.state)+" ")])],1)]}},{key:"actions",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column text-center"},[(data.state === 'new')?_c('icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Cancel print job'),expression:"'Cancel print job'"}],staticClass:"text-danger float-right",attrs:{"icon":"ban"},on:{"click":function($event){return _vm.cancelPrint(data)}}}):_vm._e()],1)]}}],null,true)})],1)]},proxy:true}:null],null,true)})}),1)]:_c('div',[_c('h3',{staticClass:"text-muted"},[_vm._v("Please select a computer to view printers.")])])],2)]),_c('modal',{attrs:{"status":_vm.isBinMapsOpen},on:{"close":_vm.toggleBinMaps}},[_c('bin-mappings')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }