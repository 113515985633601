<template>
  <container class="p-1">
    <dx-grid-with-search
      class="col"
      title="Bin Map Maintenance"
      gridName="binmaps"
      :columns="columns"
      :dataSource="binMaps"
      :toolbar="toolbar"
    >
      <template v-slot:extraActions>
        <add-button @click="handleAddBinMap" />
      </template>
      <template v-slot:actions="{ data }">
        <div>
          <icon-button
            class="m-auto text-primary pointer p-0"
            icon="pen-alt"
            v-show="permissions.PrinterCreateEdit"
            @click="handlEditBinMap(data)"
            v-tooltip.left="'Edit Bin Map'"
          />
          <icon-button
            class="ml-2 text-danger pointer p-0"
            icon="trash-alt"
            v-show="permissions.PrinterCreateEdit"
            @click="handleDeleteBinMap(data)"
            v-tooltip.left="'Delete Bin Map'"
          />
        </div>
      </template>
      <template v-slot:expirationCell="{ data }">
        <ExpiryTemplate :expiry-date="data.value" />
      </template>
    </dx-grid-with-search>
    <modal :status="isModalOpen" @close="handleClose">
      <printer-bin-mapping @submit="handleSubmit" @close="handleClose" :binMapId="binMapId" />
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "../common/DxGridWithSearch.vue";
import { mapGetters, mapState } from "vuex";
import AddButton from "../common/AddButton.vue";
import auditLog from "../../services/AuditLog";
import { booleanLookup, createLogItem } from "../../modules/helpers";
import ExpiryTemplate from "@/components/common/ExpiryTemplate.vue";
import Container from "../common/Container.vue";
import Modal from "../common/Modal.vue";
import IconButton from "../common/IconButton.vue";
import { AuditLogApi, PrintersApi } from "@/services";
import PrinterBinMapping from "../PrinterBinMappingForm.vue";
import { AuditLogItems } from "@/modules/enums";

export default {
  components: {
    DxGridWithSearch,
    AddButton,
    ExpiryTemplate,
    Container,
    Modal,
    IconButton,
    PrinterBinMapping
  },
  name: "BinMappings",
  metaInfo: {
    title: "Bin Mappings",
    titleTemplate: "IntelliPath - %s"
  },
  mounted() {
    auditLog.insertLogMessage({
      ...createLogItem({}, 7),
      comments: "Visited Admin Prefix page."
    });
  },
  created() {
    PrintersApi.getLabBinMaps().then(res => {
      this.binMaps = res || [];
    });
  },
  data: () => ({
    binMaps: [],
    isModalOpen: false,
    binMapId: null,

    columns: [
      {
        dataField: "name"
      },
      {
        dataField: "isLabDefault",
        dataType: "boolean",
        lookup: booleanLookup
      },
      {
        type: "buttons",
        caption: "Actions",
        cellTemplate: "actions"
      }
    ],
    availableLabs: [],
    isLoading: false
  }),

  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapGetters(["permissions"]),
    toolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions",
            visible: this.permissions.PrinterCreateEdit
          }
        ]
      };
    }
  },

  methods: {
    handlEditBinMap({ data }) {
      if (data) {
        this.binMapId = data.id;
      }
      this.isModalOpen = true;
    },
    async handleDeleteBinMap({ data }) {
      const confirm = await window.confirm("This action is irreversible. Do you wish to continue?");
      if (!confirm) {
        return;
      }
      await PrintersApi.deleteBinMap(data);
      await PrintersApi.getLabBinMaps().then(res => {
        this.binMaps = res || [];
      });

      const auditLogMessage = createLogItem({}, AuditLogItems.ChangeAdmin);
      auditLogMessage.comments = `Deleted the bin map ${data.name} ID ${
        data.id
      }. \n ${JSON.stringify(data, null, 2)}`;
      await AuditLogApi.insertLogMessage(auditLogMessage);
    },
    handleAddBinMap() {
      this.binMapId = null;
      this.isModalOpen = true;
    },
    handleClose() {
      this.binMapId = null;
      this.isModalOpen = false;
    },
    handleSubmit() {
      this.binMapId = null;
      this.isModalOpen = false;
      PrintersApi.getLabBinMaps().then(res => {
        this.binMaps = res || [];
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
