<template>
  <div class="card shadow">
    <div class="card-title d-flex align-items-center">
      <slot name="title"></slot>
    </div>
    <div class="card-body">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card"
};
</script>

<style lang="scss" scoped>
.card {
  /* height: 100px; */
  display: block;
  .card-title {
    margin-bottom: 2px;
  }
  .card-body {
    padding: 0px;
  }
  .avatar {
    display: flex;
    margin-right: 5px;
    & > svg {
      align-self: center;
      width: 50px;
      height: 50px;
    }
  }
  p {
    margin-bottom: 1px;
  }
}
</style>
