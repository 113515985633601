<template>
  <container class="">
    <div class="p-2 d-flex align-items-center justify-content-between">
      <page-title>Printer Maintenance</page-title>
      <button type="button" @click="toggleBinMaps" class="btn btn-primary">Bin Maps</button>
    </div>
    <div class="d-flex flex-md-wrap">
      <div class="col">
        <Card
          :class="{ active: computer.id === currentComputerId }"
          class="p-3"
          v-for="computer of computers"
          :key="computer.id"
        >
          <template v-slot:title>
            <span class="font-weight-bold text-large">🖥️{{ computer.name }}</span>
            <icon
              v-tooltip="computer.state"
              icon="circle"
              :class="{
                'text-success': computer.state === 'connected',
                'text-danger': computer.state === 'disconnected'
              }"
              class="ml-auto ml-2"
            />
            <icon-button class="mx-1" @click="handleComputerExpand(computer.id)" icon="eye" />
          </template>
        </Card>
      </div>
      <div class="col-8">
        <template v-if="currentComputerId !== null">
          <div class="p-2">
            <Card class="p-1" v-for="printer of printers" :key="printer.id">
              <template v-slot:title>
                <span class="mx-2 font-weight-bold text-large">🖨️{{ printer.name }}</span>
                <icon
                  v-tooltip="printer.state"
                  icon="circle"
                  :class="{
                    'text-success': printer.state === 'online',
                    'text-danger': printer.state === 'offline'
                  }"
                  class="ml-auto mx-2"
                />
                <DxSwitch
                  v-if="printer.id == currentPrinterId"
                  :onValueChanged="handleSetIgnoredStatus"
                  v-model="printer.isIgnored"
                  :items="booleanOptions"
                  v-tooltip="'Hide'"
                  switchedOnText="Hidden"
                  switchedOffText="Visible"
                />
                <icon-button
                  @click="handlePrinterExpand(printer.id)"
                  :icon="currentPrinterId === printer.id ? 'chevron-up' : 'chevron-down'"
                />
              </template>
              <template v-if="currentPrinterId === printer.id" v-slot:body>
                <div>
                  <dx-grid-with-search
                    :columns="printJobColumns"
                    :dataSource="printJobs"
                    :selection="selection"
                  >
                    <template v-slot:stateIcon="{ data }">
                      <div class="d-flex text-center flex-column">
                        <Icon class="mx-auto" v-bind="printJobIcon(data)">
                          {{ printJobIcon(data).icon ? "" : data.state }}
                        </Icon>
                        <p class="text-capitalize">
                          {{ data.state === "deleted" ? "Canceled" : data.state }}
                        </p>
                      </div>
                    </template>
                    <template v-slot:actions="data">
                      <div class="d-flex flex-column text-center">
                        <icon-button
                          icon="ban"
                          class="text-danger float-right"
                          @click="cancelPrint(data)"
                          v-if="data.state === 'new'"
                          v-tooltip="'Cancel print job'"
                        />
                      </div>
                    </template>
                  </dx-grid-with-search>
                </div>
              </template>
            </Card>
          </div>
        </template>
        <div v-else>
          <h3 class="text-muted">Please select a computer to view printers.</h3>
        </div>
      </div>
    </div>
    <modal :status="isBinMapsOpen" @close="toggleBinMaps">
      <bin-mappings></bin-mappings>
    </modal>
  </container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Container from "../common/Container.vue";
import PageTitle from "../common/PageTitle.vue";
import Icon from "../common/Icon.vue";
import IconButton from "../common/IconButton.vue";
import moment from "moment";
import { PrintersApi } from "@/services";
import { sortBy } from "lodash";
import DxGridWithSearch from "../common/DxGridWithSearch";
import DxSwitch from "devextreme-vue/switch";
import ArrayStore from "devextreme/data/array_store";
import { booleanLookup } from "@/modules/helpers";
import DataSource from "devextreme/data/data_source";
import BinMappings from "./BinMappings.vue";
import Modal from "../common/Modal.vue";
import CustomStore from "devextreme/data/custom_store";
import handleErrors from "@/mixins/handleErrors";
import Card from "../common/Card.vue";
export default {
  components: {
    Container,
    PageTitle,
    IconButton,
    Icon,
    BinMappings,
    Modal,
    Card,
    DxSwitch,
    DxGridWithSearch
  },
  metaInfo: {
    title: "Printer Maintenance",
    titleTemplate: "IntelliPath - %s"
  },
  name: "PrinterMaintenance",
  data() {
    return {
      computers: [],
      grid: {},
      currentPrinterId: null,
      isBinMapsOpen: false,
      currentComputerId: null,
      selection: { enabled: false },
      printers: [],
      ignoredStatus: false,
      printJobs: [],
      labPrinters: new ArrayStore({
        data: [],
        onUpdating: (key, value) => {
          const payload = {
            id: value.printerId,
            isIgnored: value.isIgnored
          };
          return PrintersApi.updatePrinter([payload]);
        }
      }),
      booleanOptions: booleanLookup.dataSource
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.all([
        PrintersApi.refreshLabPrinters(this.LabPrintNodeAPIKey),
        PrintersApi.getAllPrinters().then(res => {
          this.labPrinters = new ArrayStore({
            data: res || [],
            key: "id",
            onUpdating: (key, value) => {
              const payload = {
                id: value.printerId,
                isIgnored: value.isIgnored
              };
              return PrintersApi.updatePrinter([payload]);
            }
          });
        }),
        this.getComputers()
      ]);
    } catch (error) {
      handleErrors(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    ...mapState({ LabPrintNodeAPIKey: state => state.labSettings.LabPrintNodeAPIKey }),
    ...mapGetters(["permissions"]),
    printerDataSource() {
      return new DataSource({
        store: this.printers,
        pageSize: 10
      });
    },
    printNodeCredentials() {
      const encodedApiKey = Buffer.from(this.LabPrintNodeAPIKey).toString("base64");
      return {
        Authorization: `Basic ${encodedApiKey}`,
        "Content-Type": "application/json"
      };
    },
    dataSource() {
      return new CustomStore({
        loadMode: "raw",
        load: this.getComputers
      });
    },
    printJobColumns() {
      return [
        { dataField: "title", dataType: "string", caption: "File" },
        { dataField: "contentType", dataType: "string", caption: "Type" },
        {
          dataField: "createTimestamp",
          dataType: "datetime",
          caption: "Created",
          sortOrder: "desc",
          sortIndex: 0
        },
        {
          dataField: "state",
          cellTemplate: "stateIcon"
        },
        {
          dataField: "actions",
          caption: "Actions",
          cellTemplate: "actions",
          visible: this.permissions.PrinterCreateEdit
        }
      ];
    }
  },
  methods: {
    toggleBinMaps() {
      this.isBinMapsOpen = !this.isBinMapsOpen;
    },
    async getComputers() {
      const printnodeData = await fetch("https://api.printnode.com/computers", {
        method: "GET",
        headers: this.printNodeCredentials
      });

      const computerData = await printnodeData.json();
      this.computers = sortBy(computerData, "state");
    },
    async getPrinters(computerId) {
      const computerPrinters = await fetch(
        `https://api.printnode.com/computers/${computerId}/printers`,
        {
          method: "GET",
          headers: this.printNodeCredentials
        }
      );
      return computerPrinters.json();
    },
    async handlePrinterExpand(printerId) {
      if (this.currentPrinterId === printerId) {
        this.currentPrinterId = null;
        this.printJobs = [];
      } else {
        this.currentPrinterId = printerId;
        this.printJobs = await this.getPrintJobs(printerId);
      }
    },
    async handleSetIgnoredStatus() {
      const targetPrinter = await this.labPrinters.byKey(this.currentPrinterId);
      if (targetPrinter?.printerId) {
        this.labPrinters.update(this.currentPrinterId, {
          isIgnored: !targetPrinter.isIgnored,
          printerId: targetPrinter.printerId
        });
      }
    },
    async getPrintJobs(printerId) {
      const printerJobs = await fetch(`https://api.printnode.com/printers/${printerId}/printjobs`, {
        method: "GET",
        headers: this.printNodeCredentials
      });
      return printerJobs.json();
    },
    initializeGrid(event) {
      this.grid = event.component;
      this.grid.refresh = this.grid.reload;
      this.grid.clearSelection = this.grid.unselectAll;
      event.component.on("contentReady", () => {
        const items = event.component.option("items");
        if (items?.length) {
          setTimeout(function () {
            for (let i = 0; i < items.length; i++) event.component.collapseGroup(i);
          }, 50);
          event.component.off("contentReady");
        }
      });
    },
    async handleComputerExpand(computerId) {
      if (this.currentComputerId === computerId) {
        this.currentComputerId = null;
        this.printers = [];
        return;
      }
      this.currentComputerId = computerId;
      const printers = await this.getPrinters(computerId);
      const mappedPrinters = [];
      for (const printer of sortBy(printers, "state")) {
        try {
          const labPrinter = await this.labPrinters.byKey(printer.id);
          mappedPrinters.push({ ...labPrinter, ...printer });
        } catch (error) {
          console.log("Could not add printer " + printer?.name);
        }
      }
      this.printers = mappedPrinters;
    },
    printJobDate(data) {
      return `${moment(data.createTimestamp).format("M/D/YY hh:MM a")}`;
    },
    printJobIcon({ data }) {
      const props = {};
      switch (data.state) {
        case "done": {
          props.icon = "check";
          props.class = "text-success ml-auto";
          break;
        }
        case "error": {
          props.icon = "times";
          props.class = "text-danger ml-auto";
          break;
        }
        case "new": {
          props.icon = "plus";
          props.class = "text-warning ml-auto";
          break;
        }
        case "sent_to_client": {
          props.icon = "external-link-alt";
          props.class = "text-warning ml-auto";
          break;
        }
        case "expired": {
          props.icon = "exclamation-circle";
          props.class = "text-danger ml-auto";
          break;
        }
        case "deleted": {
          props.icon = "ban";
          props.class = "text-danger ml-auto";
          break;
        }
        default: {
          props.icon = "";
          props.class = "";
        }
      }
      return props;
    },
    async cancelPrint(data) {
      if (!this.permissions.PrinterCreateEdit) {
        alert("You do not have permission to perform this action");
        return;
      }
      const confirm = await window.confirm("Are you sure you wish to cancel this print job?");
      if (!confirm) {
        return;
      } else {
        if (data.state !== "new") {
          alert("This print job has already been sent to the client and cannot be cancelled.");
          return;
        }
        const encodedApiKey = Buffer.from(this.LabPrintNodeAPIKey).toString("base64");
        const deletedPrintJobs = await fetch(
          `https://api.printnode.com/printers/${data.printer.id}/printjobs/${data.id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Basic ${encodedApiKey}`,
              "Content-Type": "application/json"
            }
          }
        );
        const jobsDeleted = await deletedPrintJobs.json();
        if (jobsDeleted.length) {
          alert("Print job cancelled.");
          this.printers = await this.getPrintJobs(this.currentComputerId);
          this.grid.refresh;
        } else {
          alert("An error ocurred. Could not cancel print job.");
        }
        return deletedPrintJobs.json();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .dx-item.dx-list-item {
  &.dx-state-active,
  &.dx-state-focused {
    color: initial !important;
  }
}
::v-deep .active {
  opacity: 0.8;
  border: 2px solid gray;
}
</style>
