import { render, staticRenderFns } from "./PrinterMaintenance.vue?vue&type=template&id=722a24f3&scoped=true&"
import script from "./PrinterMaintenance.vue?vue&type=script&lang=js&"
export * from "./PrinterMaintenance.vue?vue&type=script&lang=js&"
import style0 from "./PrinterMaintenance.vue?vue&type=style&index=0&id=722a24f3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722a24f3",
  null
  
)

export default component.exports