<template>
  <form class="binMaps-wrapper p-3" @submit.prevent="handleSubmit">
    <div class="d-flex">
      <text-input
        class="col"
        name="name"
        label="Name"
        v-model="binMap.name"
        :validator="$v.binMap.name"
      />
      <select-input
        label="Lab Default"
        class="col"
        name="isLabDefault"
        :items="booleanOptions"
        v-model="binMap.isLabDefault"
      />
      <tag-input
        label="Priorities"
        class="col"
        :items="casePriorities"
        v-model="binMap.priorityIds"
      />
    </div>
    <div class="binMappings p-2">
      <div class="d-flex justify-content-between my-2">
        <span><b>Printer Rules</b></span>
        <icon-button icon="plus" class="btn-primary plus-btn" @click="handleAddMap" />
      </div>
      <template v-if="!isLoading">
        <div v-for="(bin, idx) in binMap.printers" :key="'binMap' + idx">
          <printer-bin-map
            :labPrinters="labPrinters"
            :allPrinters="allPrinters"
            v-model="binMap.printers[idx]"
            @delete="handleDeleteMap(idx)"
          />
        </div>
      </template>
      <div v-else>
        <loader class="mx-auto" />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary" type="submit">Submit</button>
    </div>
  </form>
</template>

<script>
import { AuditLogApi, PrintersApi } from "@/services";
import TextInput from "./common/TextInput.vue";
import IconButton from "./common/IconButton.vue";
import PrinterBinMap from "./PrinterBinMap.vue";
import { handleErrors } from "@/modules/handleErrors";
import Loader from "./common/Loader.vue";
import SelectInput from "./common/SelectInput.vue";
import { booleanLookup, createLogComment, createLogItem } from "@/modules/helpers";
import { required } from "vuelidate/lib/validators";
import TagInput from "./common/TagInput.vue";
import { cloneDeep } from "lodash";
import { AuditLogItems } from "@/modules/enums";
import { mapState } from "vuex";
export default {
  components: { TextInput, IconButton, PrinterBinMap, Loader, SelectInput, TagInput },
  name: "PrinterBinMappings",
  props: ["binMapId"],
  data() {
    return {
      binMap: {
        name: "",
        priorityIds: [],
        isLabDefault: false,
        printers: [
          {
            printerId: null,
            redirectPrinterId: null,
            bin: "",
            isLabDefault: false
          }
        ]
      },
      originalBinMap: {},
      labPrinters: [],
      allPrinters: [],
      isLoading: false,
      booleanOptions: booleanLookup.dataSource
    };
  },
  computed: {
    ...mapState({
      casePriorities: state => state.dropdowns.priorities
    })
  },
  created() {
    this.$store.dispatch("dropdowns/getPriorities");
    this.startupData();
  },
  validations() {
    return {
      binMap: {
        name: { required },
        printers: {
          $each: {
            printerId: {
              required
            }
          }
        }
      }
    };
  },
  methods: {
    async startupData() {
      try {
        this.isLoading = true;
        if (this.binMapId) {
          await PrintersApi.getBinMapById(this.binMapId).then(res => {
            if (res) {
              this.binMap = { ...res };
              this.originalBinMap = cloneDeep(res);
            }
          });
        }
        await PrintersApi.getLabPrinters().then(res => {
          this.labPrinters = res;
        });
        await PrintersApi.getAllPrinters().then(res => {
          this.allPrinters = res;
        });
        this.$store.dispatch("dropdowns/getPriorities");
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        window.notify("Please check your input and try again.", "warning");
        return;
      }
      try {
        if (this.binMapId) {
          await PrintersApi.updateBinMap({
            ...this.binMap,
            id: this.binMapId
          });
          const auditLogMessage = createLogItem({}, AuditLogItems.ChangeAdmin);
          auditLogMessage.comments = createLogComment(this.originalBinMap, this.binMap);
          await PrintersApi.createBinMap(this.binMap);
          await AuditLogApi.insertLogMessage(auditLogMessage);
          //Code for the UPDATE
        } else {
          const auditLogMessage = createLogItem({}, AuditLogItems.ChangeAdmin);
          auditLogMessage.comments = `Created the bin map ${this.binMap.name}. \n ${JSON.stringify(
            this.binMap,
            null,
            2
          )}`;
          await PrintersApi.createBinMap(this.binMap);
          await AuditLogApi.insertLogMessage(auditLogMessage);
        }
      } catch (error) {
        handleErrors(error);
      }
      this.$emit("submit");
    },
    handleAddMap() {
      this.binMap.printers = [
        ...this.binMap.printers,
        { printerId: null, redirectPrinterId: null }
      ];
    },
    handleDeleteMap(idx) {
      this.binMap.printers = [
        ...this.binMap.printers.slice(0, idx),
        ...this.binMap.printers.slice(idx + 1)
      ];
    },
    handleGetCapabilities() {}
  }
};
</script>

<style lang="scss" scoped>
.binMaps-wrapper {
  width: 55vw;
}
</style>
